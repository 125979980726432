import React, { Suspense } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScrollUp from "./Components/ScrollUp";

// Lazy load the components
const Navbar = React.lazy(() => import("./Components/Navbar"));
const Home = React.lazy(() => import("./Components/Home"));
const AboutMe = React.lazy(() => import("./Components/AboutMe"));
const Contact = React.lazy(() => import("./Components/Contact"));
const Footer = React.lazy(() => import("./Components/Footer"))

function App() {
  return (
    <div className="App">
      <Router>
        <ScrollUp />
        <Suspense fallback={<div>Loading...</div>}>
        <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<AboutMe />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
